<template >
  <div>
    <Loading v-if="loading"></Loading>
    <div v-if="!loading">
      <!-- ======= Breadcrumbs ======= -->
      <section class="breadcrumbs">
        <div class="container">
          <ol>
            <li><a @click="routeTo('home')">Home</a></li>
            <li>Blog</li>
          </ol>
          <h2>Blog</h2>
        </div>
      </section>
      <!-- End Breadcrumbs -->

      {{tag}}
      <!-- ======= Blog Section ======= -->
      <section id="blog" class="blog" >
        <h3 v-if="tag" class="text-center">News Under "{{ tag }}" Tag</h3>
        <h3 class="text-center" v-if="searchResult"> News search result(s) for "{{searchInput}}"</h3>
         <b-container>
        <b-row>
          <b-col md="12">
            <Loading v-if="Contentloading"></Loading>

 <section id="values" class="values" style="padding:0">
      <div class="container" data-aos="fade-up" style="padding:0">

        <div class="row" style="">
          
          <div
            v-for="(news, index) in recentNews"
            :key="index"
            class="col-lg-4"
            data-aos="fade-up"
            style="margin-bottom: 20px"
            data-aos-delay="200"
          >
          
        
            <div class="box blog-box" @click="routeTo('blog_description', news)" style="padding: 0;">
              <div class="news-image" :style="{ backgroundImage: 'url(' + news.bannerImages + ')',height: '200px'}" style="background-size: cover">
          
          </div>
              <div style="padding: 30px;padding-bottom:10px">
              <b-row>
              <b-col>
              <span class="post-date">{{ news.dateNews }}</span>
              </b-col>
              <b-col>
              <li class="d-flex align-items-center">   <i class="bi bi-chat-dots" style="padding-right: 10px;"></i> <a v-if='news["news-comments"]'>{{news["news-comments"].length}} Comments</a></li>
              </b-col>
              
              </b-row>
              
              <h3 class="post-title">{{ news.header }}</h3>
              <b-row>
               <!-- <b-button variant="primary" pill style="width: 50px;background-color:#d0d0d5 !important; border:none; color:#212529"  @click="routeTo('blog_description', news)"
                  ><i class="bi bi-arrow-right"></i
              ></b-button > -->
              </b-row>
             
              
              </div>

            </div>
          </div>


        </div>
      </div>
    </section>
          </b-col>
          <!-- <b-col md="4">
<div class="sidebar">
                


              <h3 class="sidebar-title">Search</h3>
              <div class="sidebar-item search-form">
                
                  <input type="text" v-model="searchInput">
                  <button type="submit" @click="search"><i class="bi bi-search"></i></button>
                
              </div>
                
              
              </div>
          </b-col> -->
        </b-row>
        
       <div class="col-sm-12">
              <b-pagination
                @input="pageChange(currentPage)"
                v-model="currentPage"
                align="center"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </div>
      </b-container>
        
      </section>
      <!-- End Blog Section -->
    </div>
  </div>
</template>
<script>
import { getDatas, getFields, getFieldsPage, getPerPage, postDataHeader } from '../assets/js/service'
import Loading from './container/Loading.vue'

export default {
    metaInfo:{
        
    // Children can override the title.
    title: 'Blog and News',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult Blog and News"},
      {property:"og:title" ,content:"First Consult Blog and News"},
      {property:"og:description" ,content:"First Consult Blog section."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/blog"},


      {name:"twitter:title" ,content:"First Consult Blog and News"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/blog"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
     {property:"Title", content:"First Consult Blog"},
      {name: 'description', content: 'First Consult News And Blog.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
    },
  components:{
Loading
  },
    data() {
      return {
        recentNews:null,
        tag:"",
        AllTags:null,
        searchInput:null,
        currentPage: 1,
        searchResult:false,
        loading:false,
        Contentloading:false,
      rows: 1,
      perPage: 1,
      }
    },
    mounted() {
      this.searchResult =false;
      this.$store.commit('set',['loading',true]);
      let token = localStorage.getItem('token');
      getFields('tags',token).then(resp=>{
        this.AllTags = resp.data;
      })
      if (!this.$store.state.TagNews) {
        getFields("news/count", token).then((resp) => {
      this.rows = Math.ceil(resp.data.count / getPerPage());
      getFieldsPage("news", 1, token,'news-comments').then((resp) => {
        this.recentNews = resp.data;
        this.tag = null;
        this.$store.commit('set',['loading',false]);
      });
    });
  }
      
      else{
        console.log('llllllllllllllll')
      this.tag = this.$store.state.TagNews.tag;
      console.log(this.tag)
      this.recentNews = this.$store.state.TagNews.news;
      this.tag = null;this.$store.commit('set',['loading',false]);
    }
    },
     watch:{
      "$store.state.loading": {
        handler(){
this.searchResult =false;
      this.$store.commit('set',['loading',true]);
      let token = localStorage.getItem('token');
      getFields('tags',token).then(resp=>{
        this.AllTags = resp.data;
      })
      if (!this.$store.state.TagNews) {
        getFields("news/count", token).then((resp) => {
      this.rows = Math.ceil(resp.data.count / getPerPage());
      getFieldsPage("news", 1, token,'news-comments').then((resp) => {
        this.recentNews = resp.data;
        this.tag = null;
        this.$store.commit('set',['loading',false]);
      });
    });
  }
      
      else{
        console.log('llllllllllllllll')
      this.tag = this.$store.state.TagNews.tag;
      console.log(this.tag)
      this.recentNews = this.$store.state.TagNews.news;
      this.tag = null;this.$store.commit('set',['loading',false]);
    }
        }
      },
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
        if(!this.loading){
          this.Contentloading = false;
        }
      },
    },
     },
     methods: {
       search(){
         this.Contentloading =true;
         postDataHeader('news/search',this.searchInput,false).then(resp=>{
           this.searchResult = true;
           this.Contentloading = false;
           this.recentNews = resp.data.Data;
         })
       },
         pageChange(currentPage) {
      let token = localStorage.getItem("token");
      getFieldsPage("news", currentPage, token).then((resp) => {
         this.recentNews = resp.data;
      });
    },
    routeTo(route,news,tag){
      if(route == "blog_description"){
        this.$store.commit("set", ["singlePageData", news]);
        this.$router.push(route+'/'+news.id);
      }else if(route == "blog"){
        let token = localStorage.getItem("token");
        postDataHeader('tags/tagRelatedDatas',{tagid:tag.id,type:'get news'},token).then(resp=>{
        this.$store.commit("set", ["TagNews", {news:resp.data.Data,tag:tag.name}]);
         this.tag = tag.name;
      this.recentNews = resp.data.Data
        })
      }
      
      else{
        this.$router.push(route);
      }
    },}
}
</script>
<style >
.card-img-top{
  height: 500px; 
    object-fit: cover;
}
</style>